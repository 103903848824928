import React from "react"
import { StaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"
import Layout from "./layout"
import Seo from "./seo"

export default function announcement() {
  return (
    <StaticQuery
      query={graphql`
        query {
          markdownRemark(frontmatter: { type: { eq: "announcement" } }) {
            html
            frontmatter {
              title
            }
          }
        }
      `}
      render={data => (
        <div>
          <p>{parse(data.markdownRemark.html)}</p>
        </div>
      )}
    />
  )
}
