import React from "react";
import { StaticQuery, graphql } from "gatsby";
import parse from "html-react-parser";

export default function Meeting() {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            filter: { frontmatter: { type: { eq: "meeting" } } }
            sort: { fields: frontmatter___order, order: ASC }
          ) {
            nodes {
              html
              frontmatter {
                title
                day
                startTime
                endTime
              }
            }
          }
        }
      `}
      render={({ allMarkdownRemark: { nodes: posts } }) => {
        return posts.map((post) => (
          <>
            <div class='col-12 col-sm-6'>
              <h2 className='blue-background blue-title'>
                {post.frontmatter.day}
              </h2>
              <p>
                <b>{parse(post.frontmatter.title)} </b>
              </p>
              <p>{parse(post.html)}</p>
            </div>
          </>
        ));
      }}
    />
  );
}
