import React from "react"
import { StaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"

export default function HomePage() {
  return (
    <StaticQuery
      query={graphql`
        query {
          markdownRemark(frontmatter: { type: { eq: "mission" } }) {
            html
            frontmatter {
              title
            }
          }
        }
      `}
      render={data => (
        <div>
          <p>{parse(data.markdownRemark.html)}</p>
        </div>
      )}
    />
  )
}
