import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Mission from "../components/mission.js";
import Announcement from "../components/announcement.js";
import Meeting from "../components/meetings.js";

const NotFoundPage = () => (
  <Layout>
    <Mission />
    <hr />
    <Announcement />
    <hr />
    <h3>SOS Meetings in New York State</h3>
    <p>
      <strong>
        <em>
          Meetings will be added as they resume due to pandemic restrictions.
        </em>
      </strong>
    </p>
    <p>
      <strong>
        <em>
          Meetings noted as "CLOSED" may only be attended by SOS volunteers and
          the site's residents. If you are interested in becoming an SOS
          volunteer, email Eric at{" "}
          <a href='https://sos-nys.org/SOSofWNY@gmail.com'>
            SOSofWNY@gmail.com
          </a>
        </em>
      </strong>
    </p>
    <div className='row' id='meeting-row'>
      <Meeting />
    </div>
    <div>
      <h2 className='blue-background blue-title'>Rochester, New York</h2>
      <p>
        <a href='https://www.sos-rochester.org/'>
          Secular Organizations for Sobriety (SOS) Rochester, NY Chapter
        </a>
      </p>
      <p>
        For more information regarding in-person & online meeting details,
        please email{" "}
        <a href='mailto:info@sos-rochester.org'>info@sos-rochester.org</a>
      </p>
      <h2 className='blue-background blue-title'>Online SOS Meetings</h2>
      <p>
        Visit{" "}
        <a href='https://www.sossobriety.org/on-line-groups'>
          sossobriety.org/on-line-groups
        </a>{" "}
        to find online SOS meetings across the country
      </p>
    </div>
    <hr />
    <h5 className='blue-background mt-5 mb-3 blue-title'>SOS & Other Links</h5>
    <div className='row' id='contact-row'>
      <ul>
        <li>
          <a href='http://www.sossobriety.org/'>www.sossobriety.org</a> [under
          construction]
        </li>
        <li>
          <a href='https://www.sossobriety.org/download-literature'>
            SOS Literature for Download (from our archives)
          </a>
        </li>
        <li>
          <a href='http://sos-rochester.org/'>SOS-Rochester</a>
        </li>
        <li>
          <a href='https://winnipegsos.com/'>SOS-Winnipeg</a>
        </li>
        <li>
          <a href='https://shareselfhelp.org/'>
            SHARE! Self-Help and Recovery Exchange
          </a>
          <li>
            <a href='https://facesandvoicesofrecovery.org/resources/mutual-aid-resources/'>
              FaVoR Guide to Mutual Aid
            </a>
          </li>
          <li>
            <a href='https://centerforinquiry.org/'>Center for Inquiry</a>
          </li>
          <li>
            <a href='https://secularhumanism.org/'>Free Inquiry</a>
          </li>
        </li>
      </ul>
    </div>

    <h5 className='blue-background mt-5 mb-3 blue-title'>Contact Info</h5>
    <div className='row' id='contact-row'>
      <div className='col-12 col-sm-6'>
        <p>
          SOS-New York
          <br />
          P. O. Box 664
          <br />
          Amherst, NY 14226-0664
        </p>
        <p>
          Eric Chinchón, Coordinator
          <br />
          Tel.: (716) 636-4869 x318
          <br />
          Email: echinchon@centerforinquiry.net
        </p>
      </div>
      <div className='col-12 col-sm-6'>
        <p>
          SOS International Clearinghouse
          <br />
          2535 West Temple Street
          <br />
          Los Angeles, CA 90026
        </p>
        <p>
          John Gennari, SOS Board Member
          <br />
          Email: john_gennari@yahoo.com
        </p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
